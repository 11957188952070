// import React, { Component, Fragment } from 'react'
import React, { useState, Fragment, useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import { v4 as uuid } from "uuid"
import { Segment } from "semantic-ui-react"

// import AuthenticatedRoute from './components/shared/AuthenticatedRoute'
import AutoDismissAlert from "./components/shared/AutoDismissAlert/AutoDismissAlert"
// import Header from "./components/shared/Header"
import RequireAuth from "./components/shared/RequireAuth"
import Home from "./components/Home"
import SignUp from "./components/auth/SignUp"
import SignIn from "./components/auth/SignIn"
import SignOut from "./components/auth/SignOut"
import ChangePassword from "./components/auth/ChangePassword"
import User from "./components/auth/User"
import UserPosts from "./components/UserPosts"
import { Helmet } from "react-helmet"
import "semantic-ui-css/semantic.min.css"

const App = () => {
	const [user, setUser] = useState(null)
	const [msgAlerts, setMsgAlerts] = useState([])

	// console.log('user in app', user)
	// console.log("local", JSON.parse(localStorage.getItem('user')))

	const appHeight = () => {
		const doc = document.documentElement
		doc.style.setProperty("--app-height", `${window.innerHeight}px`)
	}
	window.addEventListener("resize", appHeight)
	appHeight()

	useEffect(() => {
		if (user !== null) {
			// console.log("found user", user)
			localStorage.setItem("user", JSON.stringify(user))
		} else {
			// console.log(typeof localStorage.getItem('user'))
			setUser(JSON.parse(localStorage.getItem("user")))
		}
		// 	const loggedInUser = localStorage.getItem('user')
		// 	console.log("logged in user", loggedInUser)
		// 	if(loggedInUser !== 'undefined'){
		// 		console.log('not undefined')
		// 		// const foundUser = JSON.parse(loggedInUser)
		// 		// setUser(foundUser)
		// 	}
	}, [user])

	const clearUser = () => {
		setUser(null)
	}

	const deleteAlert = (id) => {
		setMsgAlerts((prevState) => {
			return prevState.filter((msg) => msg.id !== id)
		})
	}

	const msgAlert = ({ heading, message, variant }) => {
		const id = uuid()
		setMsgAlerts(() => {
			return [{ heading, message, variant, id }]
		})
	}

	return (
		<Segment inverted vertical textAlign="center">
			<Fragment>
				{/* <Header user={user} /> */}

				<Routes>
					<Route
						path="/"
						element={<Home msgAlert={msgAlert} user={user} />}
					/>
					<Route
						path="/my-posts"
						element={<UserPosts msgAlert={msgAlert} user={user} />}
					/>
					<Route
						path="/user"
						element={
							<User
								msgAlert={msgAlert}
								user={user}
								setUser={setUser}
							/>
						}
					/>
					<Route
						path="/sign-up"
						element={
							<SignUp msgAlert={msgAlert} setUser={setUser} />
						}
					/>
					<Route
						path="/sign-in"
						element={
							<SignIn
								msgAlert={msgAlert}
								setUser={setUser}
								user={user}
							/>
						}
					/>
					<Route
						path="/sign-out"
						element={
							<RequireAuth user={user}>
								<SignOut
									msgAlert={msgAlert}
									clearUser={clearUser}
									user={user}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/change-password"
						element={
							<RequireAuth user={user}>
								<ChangePassword
									msgAlert={msgAlert}
									user={user}
								/>
							</RequireAuth>
						}
					/>
				</Routes>
				<Helmet>
					<link
						rel="apple-touch-icon"
						sizes="180x180"
						href="%PUBLIC_URL%/tinyweather-icon-touch.png"
						/>
				</Helmet>
						{msgAlerts.map((msgAlert) => (
							<AutoDismissAlert
								key={msgAlert.id}
								heading={msgAlert.heading}
								variant={msgAlert.variant}
								message={msgAlert.message}
								id={msgAlert.id}
								deleteAlert={deleteAlert}
							/>
						))}
			</Fragment>
		</Segment>
	)
}

export default App
